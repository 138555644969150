$first-color: #1c1c31;
$second-color: #96ce04;
$third-color: white;

.searchboxSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.searchbox {
    display: block;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    background: none;
    background-color: #eee;
    border-radius: 8px;
    color: #53565A;
    font-size: 20px;
    font-weight: 300;
    transition: 0.4 ease-out;

    &:focus {
        box-shadow: 0px 0px 8px 3px $second-color;
    }
}