@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,700,900");

$first-color: #1c1c31;
$second-color: #96ce04;
$third-color: white;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif;
  background-color: $first-color;
  color: $third-color;
}

header {
  padding: 50px 15px 0px;

  h1 {
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
  }

  h2 {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin: 30px;
  }
}

main {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  section {
    padding: 20px;
  }
}

