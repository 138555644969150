$first-color: #1c1c31;
$second-color: #96ce04;
$third-color: white;

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $first-color;
  overflow-y: scroll;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 25px;
    color: #fff;

    h2 {
      font-size: 28px;
      font-weight: 600;

      span {
        font-weight: 200;
        font-size: 20px;
      }
    }

    .rating {
      margin-bottom: 30px;
    }

    .plot {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;

      img {
        max-width: 600px;
        padding: 15px;
      }

      p {
        padding: 15px;
      }
    }

    .close {
      display: inline-block;
      padding: 15px 30px;
      font-size: 18px;
      font-weight: 700;
      background: $second-color;
      color: $first-color;
      border-radius: 8px;
      border: none;
      outline: none;
      appearance: none;
      cursor: pointer;
      transition: 0.4s ease-out;

      &:hover {
        background: $first-color;
        color: $second-color;
        border: 1px solid $second-color;
      }
     }
  }
}