@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{margin:0;padding:0;box-sizing:border-box}body{font-family:"Raleway", sans-serif;background-color:#1c1c31;color:#fff}header{padding:50px 15px 0px}header h1{font-size:36px;font-weight:500;text-align:center;text-transform:capitalize}header h2{font-size:28px;font-weight:500;text-align:center;margin:30px}main{width:100%;max-width:1024px;margin:0 auto}main section{padding:20px}

.SearchBar_searchboxSection__2ejwb{display:flex;flex-direction:column;justify-content:space-between}.SearchBar_searchbox__2rgP3{display:block;width:100%;padding:15px;border:none;outline:none;background:none;background-color:#eee;border-radius:8px;color:#53565A;font-size:20px;font-weight:300;transition:0.4 ease-out}.SearchBar_searchbox__2rgP3:focus{box-shadow:0px 0px 8px 3px #96ce04}

.Movies_results__2_BsM{display:flex;flex-wrap:wrap;margin:0px -15px}

.Movie_result__2lEi3{width:33.33%;min-width:250px;padding:15px;display:flex;flex-direction:column;cursor:pointer}.Movie_result__2lEi3:hover{transform:scale(1.03);transition:0.4s ease-out;border:1px solid #96ce04}.Movie_result__2lEi3 img{display:block;width:100%}.Movie_result__2lEi3 h3{text-align:center;color:#1c1c31;font-size:20px;font-weight:600;padding:10px;background:#96ce04;flex:1 1 100%;transition:0.4s ease-out}.Movie_result__2lEi3 h3:hover{background:#fff;color:#1c1c31}

.Popup_popup__28jak{position:fixed;top:0;left:0;width:100vw;height:100vh;background-color:#1c1c31;overflow-y:scroll}.Popup_popup__28jak .Popup_content__2hpGb{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;max-width:1024px;margin:0 auto;padding:25px;color:#fff}.Popup_popup__28jak .Popup_content__2hpGb h2{font-size:28px;font-weight:600}.Popup_popup__28jak .Popup_content__2hpGb h2 span{font-weight:200;font-size:20px}.Popup_popup__28jak .Popup_content__2hpGb .Popup_rating__19tir{margin-bottom:30px}.Popup_popup__28jak .Popup_content__2hpGb .Popup_plot__1YSfY{display:flex;flex-direction:column;justify-content:center;align-items:center;margin:0}.Popup_popup__28jak .Popup_content__2hpGb .Popup_plot__1YSfY img{max-width:600px;padding:15px}.Popup_popup__28jak .Popup_content__2hpGb .Popup_plot__1YSfY p{padding:15px}.Popup_popup__28jak .Popup_content__2hpGb .Popup_close__31fyP{display:inline-block;padding:15px 30px;font-size:18px;font-weight:700;background:#96ce04;color:#1c1c31;border-radius:8px;border:none;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;cursor:pointer;transition:0.4s ease-out}.Popup_popup__28jak .Popup_content__2hpGb .Popup_close__31fyP:hover{background:#1c1c31;color:#96ce04;border:1px solid #96ce04}

